import Modal from 'react-bootstrap/Modal';
import useWeb3 from "../../contexts/Web3Context/useWeb3"
import React from "react";

function Notice() {
    const useweb3 = useWeb3();
    const { web3error } = useweb3.state;

    return (
        <Modal
            show={web3error !== ''}
            onHide={() => {useweb3.dispatch({data:{web3error: ''}})}}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    ERROR
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2 style={{color:'red'}}>{web3error}</h2>
            </Modal.Body>
        </Modal>
    );
}

export default Notice;