import Alert from 'react-bootstrap/Alert';

function Notice(args) {
    if (args.value !== '') {
        let variant = 'warning';
        if(args.variant !== undefined && args.variant !== '') variant = args.variant;
        return (
            <Alert key={variant} variant={variant} onClose={() => args.setNotice('')} dismissible>
                {args.value}
            </Alert>
        );
    }
    return (<></>);
}

export default Notice;