import Button from "react-bootstrap/Button";
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import BigNumber from "bignumber.js";
import React, { useEffect, useState, useCallback, useReducer, useMemo } from "react";
import useWeb3 from "../contexts/Web3Context/useWeb3"
import { chainInfo, contractInfo, bridgeApi } from "../contexts/Web3Context/config"
import Modal from "react-bootstrap/Modal";
import { CiCircleList } from "react-icons/ci";
import { BiRefresh } from "react-icons/bi";

const crossTokenHubABI = require("../contracts/CrossTokenHubABI.json");
const tokenABI = require("../contracts/ERC20ABI.json");

BigNumber.set({ DECIMAL_PLACES: 6, ROUNDING_MODE: 6 })

const Records = (args) => {
    const useweb3 = useWeb3();
    const { web3, account, networkId } = useweb3.state;
    const [records, setRecords] = useState('');
    const [error, setError] = useState('');
    const [notice, setNotice] = useState('');
    const [recordsLoading, setRecordsLoading] = useState(false);

    const [count, setCount] = useState(0);

    useEffect(() => {
        let intervalId = null;
        if (count > 0) {
            intervalId = setInterval(() => {
                setCount(prevCount => prevCount - 1);
            }, 1000);
        } else if (count === 0) {
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [count]);


    const STATUS = useMemo(() => {
        return {
            stop: 0,
            start: 1,
            switching: 2,
            claiming: 3
        }
    }, []);

    const reducer = (state, action) => {
        const { status, element, record } = action;
        switch (status) {
            case STATUS.start:
                return { status: status, element: element, record: record };
            case STATUS.stop:
                return { status: status, element: null, record: null };
            default:
                return { ...state, status: status };
        }
    };

    const [state, dispatch] = useReducer(reducer, {
        status: 0, // 0 = stop, 1 = start, 2 = claiming
        element: null,
        record: null
    });

    const loadRecords = async (address) => {
        setRecordsLoading(true);
        try {
            const response = await fetch(bridgeApi + '/records/' + address, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });

            if (!response.ok) {
                setError(`Error! status: ${response.status}`);
                return [];
            }
            const result = await response.json();

            if (result.code === 0) {
                return result.data;
            } else if (result.code) {
                setError(result.message);
            } else {
                setError(result);
            }
            return [];
        } catch (err) {
            setError(err.message);
            return [];
        } finally {
            setRecordsLoading(false);
            setCount(5);
        }
    };

    // Records
    const refreshRecords = useCallback(async () => {
        if (account !== '') {
            setRecords(await loadRecords(account));
        }
    }, [account])

    useEffect(() => {
        if (records === '' && account !== '') {
            refreshRecords();
        }
    }, [records, account, refreshRecords]);

    const statusNames = {
        "0": 'Pending',
        "1": 'Processing',
        "2": 'Processing',
        "3": 'Done!',
    };

    const elements = [];
    if (records.length > 0) {
        let i = 1;
        try {
            records.forEach((row) => {
                const dstContractAddr = row.contractAddr.toLowerCase();
                const amount = (new BigNumber(row.amount)).div(Math.pow(10, contractInfo[dstContractAddr].decimals * 1));
                elements.push(
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                        key={i}
                    >
                        <div className="ms-4 me-auto">
                            <div>
                                {row.srcTxHash ? <a rel="noopener noreferrer" target='_blank' href={chainInfo[row.srcChainId].scanLink + row.srcTxHash} >{chainInfo[row.srcChainId].name}</a> : chainInfo[row.srcChainId].name}
                                {' '} →  {' '}
                                {row.dstTxHash ? <a rel="noopener noreferrer" target='_blank' href={chainInfo[row.dstChainId].scanLink + row.dstTxHash} >{chainInfo[row.dstChainId].name}</a> : chainInfo[row.dstChainId].name}
                            </div>
                            <div className="fw-bold">
                                {amount.toString(10)} {contractInfo[dstContractAddr].symbol}
                            </div>
                            {row.signatures !== '' && row.status === 0 ?
                                <Button variant="warning" style={{ width: '100%' }} onClick={(e) => { dispatch({ status: STATUS.start, element: e, record: row }) }}>Claim {contractInfo[dstContractAddr].symbol}</Button>
                                : statusNames[row.status]
                            }
                        </div>
                        <Badge bg="warning" pill>
                            {moment(parseInt(row.time)).format("YYYY-MM-DD HH:mm")}
                        </Badge>
                    </ListGroup.Item>
                )
                i++;
            })
        } catch (e) {
            console.error(e.message)
        }
    }

    const claim = useCallback(async (e, record) => {
        const crossTokenHubAddr = chainInfo[record.dstChainId]['crossTokenHub'];
        if (!crossTokenHubAddr) {
            setError('Invalid crossTokenHubAddr');
            dispatch({ status: STATUS.stop });
            return;
        }
        e.target.disabled = true;
        const token = new web3.eth.Contract(tokenABI, record.contractAddr);
        const balance = await token.methods.balanceOf(account).call();
        if (web3.utils.toBN(balance).cmp(web3.utils.toBN(record.amount)) < 0) {
            setNotice('The amount you are claiming is too large, or there are too many claiming requests. For the safety of your funds, please contact the administrator for manual review.');
            e.target.disabled = false;
            dispatch({ status: STATUS.stop });
            return;
        }
        setNotice('Please confirm the transaction for sending');
        dispatch({ type: 'stop' });
        const contract = new web3.eth.Contract(crossTokenHubABI, crossTokenHubAddr);
        await contract.methods.claim(
            record.tid,
            record.contractAddr,
            record.amount,
            record.signatures
        ).send({ from: account })
            .on('transactionHash', function (hash) {

                setNotice('⌛️ Waiting for the transaction to be completed...');
            })
            .on('receipt', async function (receipt) {
                setNotice('Done!');
            })
            .on('error', function (error) {
                e.target.disabled = false;
                setError(error.message);
            });
    }, [account, web3, STATUS]);

    useEffect(() => {
        if (!account || state.status === STATUS.stop) return;
        if (state.status === STATUS.start) {
            if (networkId !== state.record.dstChainId) {
                dispatch({ status: STATUS.switching });
                useweb3.dispatch(
                    { type: 'CONN', data: { targetNetworkId: state.record.dstChainId } }
                );
            } else {
                dispatch({ status: STATUS.claiming });
                claim(state.element, state.record);
            }
        } else if (state.status === STATUS.switching && networkId === state.record.dstChainId && account !== '') {
            dispatch({ status: STATUS.claiming });
            claim(state.element, state.record);

        }
    }, [state, networkId, claim, STATUS, account, useweb3]);

    return (
        <>
            <br />
            <Card border="warning">
                <Card.Header style={{ fontWeight: 'bolder', borderColor: '#F9BC23', background: 'linear-gradient(122deg, #FEFFF0 30%, rgba(255, 250, 100, 0.34) 60%)' }}>
                    <CiCircleList size='1.7em' /> Records <Button variant="none" onClick={refreshRecords} disabled={recordsLoading || count > 0}>{recordsLoading ? 'Loading...' : (count > 0 ? `${count}` : <BiRefresh size='1.8em' />)}</Button>
                </Card.Header>
                <Card.Body>
                    {elements.length > 0
                        ? <ListGroup as="ol" numbered variant="flush">{elements}</ListGroup>
                        : "No Records!"}

                </Card.Body>
            </Card>

            <br />

            <Modal
                show={notice !== '' || error !== ''}
                onHide={() => {
                    setNotice('');
                    setError('');
                }}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {error !== '' ? 'ERROR' : 'NOTICE'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error !== '' ? <h2 style={{ color: 'red' }}>{error}</h2> : <h2>{notice}</h2>}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Records;
