import React, { useCallback, useEffect, useState } from 'react'
import useWeb3 from '../contexts/Web3Context/useWeb3'
import {
  chainInfo,
  TOKEN_UCC_ID,
  TOKEN_USDT_ID,
  TOKEN_TBT_ID,
} from '../contexts/Web3Context/config'
import BigNumber from 'bignumber.js'
import Card from 'react-bootstrap/Card'
import { BiRefresh, BiBookAdd } from 'react-icons/bi'
import { CiWallet } from 'react-icons/ci'
import '../App.css'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'

const tokenABI = require('../contracts/ERC20ABI.json')

function Account() {
  const useweb3 = useWeb3()
  const { web3, account, networkId } = useweb3.state

  const [balances, setBalances] = useState(['', '', ''])

  const addToken = (tokenId) => {
    if (!account || networkId === 0) return
    if (!window.ethereum) return
    const takenInfo = chainInfo[networkId]['tokens'][tokenId]
    window.ethereum
      .request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: takenInfo.contractAddr,
            symbol: takenInfo.symbol,
            decimals: takenInfo.decimals,
            image: takenInfo.logo,
          },
        },
      })
      .then((success) => {
        if (success) {
          console.log('USDT successfully added to wallet!')
        } else {
          throw new Error('Something went wrong.')
        }
      })
      .catch(console.error)
  }

  const getUccBalance = useCallback(async () => {
    const balance = await web3.eth.getBalance(account)
    return BigNumber(web3.utils.fromWei(balance, 'ether'), 10).toFixed(2)
  }, [account, web3])

  const getTokenBalance = useCallback(
    async (tokenId) => {
      if (!account) return '0'
      const takenInfo = chainInfo[networkId]['tokens'][tokenId]
      const token = new web3.eth.Contract(tokenABI, takenInfo.contractAddr)
      const balance = await token.methods.balanceOf(account).call()
      const amount = new BigNumber(balance.toString())
        .div(Math.pow(10, takenInfo.decimals * 1))
        .toFixed(2)
      return amount
    },
    [account, networkId, web3]
  )

  const getBalance = useCallback(
    async (tokenId) => {
      if (!account || networkId === 0 || chainInfo[networkId] === undefined)
        return 0
      if (tokenId === TOKEN_UCC_ID) {
        const balance = await getUccBalance()
        setBalances(
          balances.map((v, i) => {
            if (i === TOKEN_UCC_ID) return balance
            else return v
          })
        )
      } else {
        // const takenInfo = chainInfo[networkId]['tokens'][tokenId];
        // const token = new web3.eth.Contract(tokenABI, takenInfo.contractAddr);
        // const balance = await token.methods.balanceOf(account).call();
        // const amount = (new BigNumber(balance.toString())).div(Math.pow(10, takenInfo.decimals*1)).toFixed(2);
        const amount = await getTokenBalance(tokenId)
        setBalances(
          balances.map((v, i) => {
            if (i === tokenId) return amount
            else return v
          })
        )
      }
    },
    [account, networkId, getUccBalance, balances, getTokenBalance]
  )

  const conn = useCallback(
    (id) => {
      useweb3.dispatch({ data: { targetNetworkId: id } })
    },
    [useweb3]
  )

  useEffect(() => {
    if (!networkId || !account) return
    const initBalance = async () => {
      const ucc = await getUccBalance()
      const usdt = await getTokenBalance(TOKEN_USDT_ID)
      const tbt = await getTokenBalance(TOKEN_TBT_ID)
      setBalances([ucc, usdt, tbt])
    }
    initBalance()
  }, [account, getTokenBalance, getUccBalance, networkId])

  return (
    <Card border='warning'>
      <Card.Header
        style={{
          fontWeight: 'bolder',
          borderColor: '#F9BC23',
          background:
            'linear-gradient(122deg, #FEFFF0 30%, rgba(255, 250, 100, 0.34) 60%)',
        }}
      >
        {' '}
        <CiWallet size='1.8em' /> My Wallet
      </Card.Header>
      {account && networkId > 0 && chainInfo[networkId] !== undefined ? (
        <ListGroup variant='flush'>
          <ListGroup.Item>
            <span className='textLabel'>Wallet Address:</span>{' '}
            {account.substring(0, 6)}...{account.substring(38, 42)}
          </ListGroup.Item>
          <ListGroup.Item>
            <span className='textLabel'>Current Network:</span>{' '}
            {chainInfo[networkId].name}
          </ListGroup.Item>
          <ListGroup.Item>
            <span className='textLabel'>
              {networkId === 137 ? 'MATIC' : 'UCC'} Balance:
            </span>{' '}
            {balances[TOKEN_UCC_ID]}{' '}
            <BiRefresh
              style={{ cursor: 'pointer' }}
              onClick={() => getBalance(TOKEN_UCC_ID)}
              size='1em'
            />
          </ListGroup.Item>
          <ListGroup.Item>
            <span className='textLabel'>USDT Balance:</span>{' '}
            {balances[TOKEN_USDT_ID]}{' '}
            <BiRefresh
              style={{ cursor: 'pointer' }}
              onClick={() => getBalance(TOKEN_USDT_ID)}
              size='1em'
            />
            <BiBookAdd
              style={{ cursor: 'pointer' }}
              onClick={() => addToken(TOKEN_USDT_ID)}
              size='1.8em'
              className={'addToken'}
            />
          </ListGroup.Item>
          <ListGroup.Item>
            <span className='textLabel'>TBT Balance:</span>{' '}
            {balances[TOKEN_TBT_ID]}{' '}
            <BiRefresh
              style={{ cursor: 'pointer' }}
              onClick={() => getBalance(TOKEN_TBT_ID)}
              size='1em'
            />
            <BiBookAdd
              style={{ cursor: 'pointer' }}
              onClick={() => addToken(TOKEN_TBT_ID)}
              size='1.8em'
              className={'addToken'}
            />
          </ListGroup.Item>
        </ListGroup>
      ) : (
        <Card.Body>
          <Card.Text>
            {networkId !== 792 || !account ? (
              <Button variant='warning' onClick={(e) => conn(792)}>
                {' '}
                Connect to uChain{' '}
              </Button>
            ) : (
              <Button variant='warning' onClick={(e) => conn(137)}>
                {' '}
                Connect to Polygon{' '}
              </Button>
            )}
          </Card.Text>
        </Card.Body>
      )}
    </Card>
  )
}

export default Account
