import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import useWeb3 from '../contexts/Web3Context/useWeb3'
import { actions } from '../contexts/Web3Context'
import { chainInfo, chainConfig } from '../contexts/Web3Context/config'
import Web3 from 'web3'

import { useEffect, useCallback } from 'react'

// 提取出一些复杂的逻辑到自定义钩子中
function useConnect(networkId, account, targetNetworkId) {
  const [show, setShow] = useState(false)
  const [defaultBtnText, setDefaultBtnText] = useState('')

  useEffect(() => {
    if (
      targetNetworkId > 0 &&
      (targetNetworkId !== networkId || account === '') &&
      networkId !== 0
    ) {
      setShow(true)
      setDefaultBtnText(`Connect to ${chainInfo[targetNetworkId].name}`)
    } else {
      setShow(false)
    }
  }, [networkId, account, targetNetworkId])

  return { show, defaultBtnText }
}

function Conn() {
  // 使用 useState 钩子来管理状态
  const [error, setError] = useState(null)
  const [btnState, setBtnState] = useState({ isLoading: false, text: '' })
  const useweb3 = useWeb3()
  const { networkId, account, targetNetworkId } = useweb3.state

  // 使用自定义钩子来处理复杂的逻辑
  const { show, defaultBtnText } = useConnect(
    networkId,
    account,
    targetNetworkId
  )

  // 使用 useCallback 钩子来包装函数
  const onHide = useCallback(() => {
    useweb3.dispatch({ data: { targetNetworkId: 0 } })
    setBtnState({ isLoading: false, text: '' })
    setError('')
  }, [])

  // 切换网络
  const switchNetwork = async (chainId) => {
    if (chainId === 0 || chainId === 1) return
    try {
      if (window.ethereum) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.numberToHex(chainId) }],
        })

        // 设置网络
        useweb3.dispatch({
          type: actions.SET_NETWORKID,
          data: { networkId: chainId },
        })
      } else {
        setError('Open in your wallet, please!')
      }
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName='modal-90w'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Connect Wallet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-center'>
          <Button
            variant='warning'
            style={{ width: '100%' }}
            onClick={async () => {
              setBtnState({ isLoading: true, text: 'Connecting...' })
              try {
                const web3 = new Web3(Web3.givenProvider)
                const chainId = await web3.eth.getChainId()
                if (chainId !== targetNetworkId) {
                  // 切换网络
                  await switchNetwork(targetNetworkId)
                  //   setError('Please switch to the correct network')
                  //   return
                }
                const accounts = await web3.eth.requestAccounts()

                if (accounts.length === 0) {
                  setError('No account found')
                  return
                } else {
                  onHide()
                }

                useweb3.dispatch({
                  type: actions.SET_ACCOUNT,
                  data: { account: accounts[0] },
                })
              } catch (error) {
                setError(error.message)
              } finally {
                setBtnState({ isLoading: false, text: '' })
              }
            }}
          >
            {btnState.isLoading ? (
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              ></span>
            ) : null}
            {btnState.text || defaultBtnText}
          </Button>
        </div>
        <div className='d-flex justify-content-center'>
          <p className='text-danger'>{error}</p>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Conn
