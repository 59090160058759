import React, { useReducer, useCallback, useEffect } from 'react'
import Web3 from 'web3'
import Web3Context from './Web3Context'
import { reducer, actions, initialState } from './state'

function Web3Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const init = useCallback(async () => {
    let data = {}
    if (window.ethereum) {
      data.web3 = new Web3(Web3.givenProvider)
      //const networkId = await window.ethereum.request({ method: 'eth_chainId' });
      data.networkId = await data.web3.eth.net.getId()

      const accounts = await window.ethereum.request({ method: 'eth_accounts' })

      if (accounts && accounts.length > 0) {
        data.account = accounts[0]
      }
    }
    dispatch({
      type: actions.init,
      data: data,
    })
  }, [])

  useEffect(() => {
    const tryInit = async () => {
      try {
        init()
      } catch (err) {
        dispatch({
          type: actions.error,
          data: { web3error: err.message },
        })
      }
    }
    tryInit()
  }, [init])

  // useEffect(() => {
  //     if(!window.chainId) return;
  //     console.log('useEffect window.ethereum.chainId', window.ethereum.chainId);
  //     dispatch({
  //         type: '',
  //         data: {networkId: Web3.utils.hexToNumber(window.ethereum.chainId)}
  //     });
  // }, [window.ethereum.chainId]);

  useEffect(() => {
    if (!window.ethereum) return
    window.ethereum.autoRefreshOnNetworkChange = true

    window.ethereum.on('chainChanged', (networkId) => {
      if (networkId === 0) {
        dispatch({
          type: actions.account,
          data: { networkId: Web3.utils.hexToNumber(792) },
        })
      }

      dispatch({
        type: '',
        data: { networkId: Web3.utils.hexToNumber(networkId) },
      })
    })
    window.ethereum.on('accountsChanged', (accounts) => {
      if (accounts.length === 0) {
        dispatch({
          type: actions.account,
          data: { account: '' },
        })
        return
      }
      dispatch({
        type: actions.account,
        data: { account: accounts[0] },
      })
    })
    window.ethereum.on('error', (e) => {
      dispatch({
        type: actions.error,
        data: { web3error: e.message },
      })
    })
  }, [])

  return (
    <Web3Context.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}

export default Web3Provider
