import React, { useReducer, useState, useMemo, useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Notice from "./Notice";
import useWeb3 from "../contexts/Web3Context/useWeb3"
import { chainInfo, myChainId, TOKEN_USDT_ID, TOKEN_TBT_ID, bridgeApi } from "../contexts/Web3Context/config"
import { CiBadgeDollar } from "react-icons/ci";
import BigNumber from "bignumber.js";

const crossTokenHubABI = require("../contracts/CrossTokenHubABI.json");
const tokenABI = require("../contracts/ERC20ABI.json");

const crossTypes = {
    "0": { srcChainId: 0, dstChainId: 0 },
    "26": { srcChainId: 137, dstChainId: 792 },
    "62": { srcChainId: 792, dstChainId: 137 },
}

function Transfer() {

    const STATUS = useMemo(() => {
        return {
            stop: 0,
            start: 1,
            switching: 2,
            sending: 3,
            connecting: 4
        }
    }, []);

    const reducer = (state, action) => {
        const { type, data } = action;
        if (type === 'crossType') {
            return { ...state, crossType: data, srcChainId: crossTypes[data].srcChainId, dstChainId: crossTypes[data].dstChainId };
        }
        return { ...state, ...data };
    };

    const useweb3 = useWeb3();
    const { web3, account, networkId } = useweb3.state;

    const [state, dispatch] = useReducer(reducer, {
        srcChainId: 137,
        dstChainId: 792,
        coin: TOKEN_USDT_ID,
        amount: '',
        crossType: 26,
        element: null,
        status: 0,
    });

    const txBtxStateDefaultText = 'Transfer'
    const [txBtnState, setTxBtxState] = useState({ isLoading: false, text: txBtxStateDefaultText });

    const [notice, setNotice] = useState('');

    const eventNotice = useCallback(async (chainId, blockNumber, targetNetworkId) => {
        try {
            const response = await fetch(bridgeApi+'/events/transferout/'+chainId+'/'+blockNumber, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });

            if (!response.ok) {
                setNotice(`Error! status: ${response.status}`);
                return;
            }
            const result = await response.json();

            if(result.code === 0){
                setNotice('Please refresh the `Records` now.');
                useweb3.dispatch(
                    {type: 'CONN', data: {targetNetworkId: targetNetworkId}}
                );
                return;
            }else if(result.code){
                setNotice(result.message);
            }else{
                setNotice(result);
            }
            return [];
        } catch (err) {
            setNotice(err.message);
            return [];
        } finally {
            // setRecordsLoading(false);
        }
    }, [useweb3]);

    const transferOut = useCallback(async () => {
        if (!state.amount) {
            setNotice('Please input token amount');
            return;
        }
        if (!state.srcChainId) {
            setNotice('Please select transfer direction');
            return;
        }
        if (!state.coin) {
            setNotice('Please select token');
            return;
        }
        if (state.srcChainId > 0 && (state.srcChainId !== networkId || account === '')) {
            useweb3.dispatch({ data: { targetNetworkId: state.srcChainId } });
            return;
        }
        const tokenInfo = chainInfo[state.srcChainId]['tokens'][state.coin];
        const crossTokenHubAddr = chainInfo[state.srcChainId]['crossTokenHub'];
        if (!tokenInfo.contractAddr) {
            setNotice('Invalid token contractAddr');
            return;
        }
        if (!crossTokenHubAddr) {
            setNotice('Invalid crossTokenHubAddr');
            return;
        }
        if (notice !== '') {
            setNotice('');
        }
        const contract = new web3.eth.Contract(crossTokenHubABI, crossTokenHubAddr);

        const amount = (new BigNumber(state.amount.toString())).multipliedBy(Math.pow(10, tokenInfo.decimals * 1)).toString();
        // if (state.srcChainId !== myChainId) {

        // }
        const token = new web3.eth.Contract(tokenABI, tokenInfo.contractAddr);
        const myBalance = await token.methods.balanceOf(account).call();
        if (web3.utils.toBN(myBalance).cmp(web3.utils.toBN(amount)) < 0) {
            setNotice('Insufficient balance');
            return;
        }
        const allowBalance = await token.methods.allowance(account, contract.options.address).call({ from: account });
        if (web3.utils.toBN(allowBalance).cmp(web3.utils.toBN(amount)) < 0) {
            setNotice('Please confirm `Approve` in your wallet.');
            setTxBtxState({ isLoading: true, text: '⌛ Waiting for `Approve`...' });
            await token.methods.approve(contract.options.address, amount).send({ from: account })
                .on('transactionHash', function (hash) {
                    setNotice('⌛️ Waiting for the `Approve` to be completed...');
                })
                .on('receipt', function (receipt) {
                })
                .on('error', function (error) {
                    setNotice(error.message);
                    setTxBtxState({ isLoading: false, text: txBtxStateDefaultText });
                });
        }

        const allowBalance2 = await token.methods.allowance(account, contract.options.address).call({ from: account });
        if (web3.utils.toBN(allowBalance2).cmp(web3.utils.toBN(amount)) < 0) {
            setTxBtxState({ isLoading: false, text: 'Transfer' });
            return;
        }
        setNotice('Please confirm the transaction for sending');
        setTxBtxState({ isLoading: true, text: '⌛Sending...' });
        await contract.methods.transferOut(
            state.dstChainId,
            tokenInfo.contractAddr,
            amount
        ).send({ from: account, value: 0 })
            .on('transactionHash', function (hash) {
                setNotice('');
                setTxBtxState({ isLoading: true, text: '⌛️ Waiting for completion...' });
            })
            .on('receipt', function (receipt) {
                if (state.dstChainId === myChainId) {
                    setNotice('Transaction processing, automatic deposit will be made after 1 minute.');
                } else {
                    setNotice('Please refresh the `Records` after 1 minute, and claim USDT in Polygon Network.');
                }
                eventNotice(state.srcChainId, receipt.blockNumber, state.dstChainId);
                setTxBtxState({ isLoading: false, text: txBtxStateDefaultText });
            })
            .on('error', function (error) {
                setNotice(error.message);
                setTxBtxState({ isLoading: false, text: txBtxStateDefaultText });
            });
    }, [account, eventNotice, networkId, notice, state, useweb3, web3])

    const onChangeCrossType = async e => {
        dispatch({ type: "crossType", data: e.target.value })
    };

    const onChangeCoin = async e => {
        dispatch({ type: "coin", data: { coin: e.target.value } })
    };

    const onChangeAmount = async e => {
        dispatch({ type: "amount", data: { amount: e.target.value } })
    };

    useEffect(() => {
        if(!account || state.status === STATUS.stop) {
            if(!account && state.status !== STATUS.connecting){
                dispatch({type: 'status', data: {status: STATUS.connecting}});
                useweb3.dispatch(
                    {type: 'CONN', data: {targetNetworkId: state.srcChainId}}
                );
            }else if(account && state.status === STATUS.connecting){
                dispatch({type: 'status', data: {status: STATUS.stop}});
            }
            return;
        };
        if(state.status === STATUS.start) {
            if(networkId !== state.srcChainId){
                dispatch({type: 'status', data: {status: STATUS.switching}});
                useweb3.dispatch(
                    {type: 'CONN', data: {targetNetworkId: state.srcChainId}}
                );
            }else{
                dispatch({type: 'status', data: {status: STATUS.sending}});
                transferOut()
            }
        }else if(state.status === STATUS.switching && networkId === state.srcChainId && account !== ''){
            dispatch({type: 'status', data: {status: STATUS.sending}});
            transferOut();

        }
    }, [state, networkId, transferOut, STATUS, account, useweb3]);


    return (
        <>
            <br />
            <Card border="warning">
                <Card.Header style={{ fontWeight: 'bolder', borderColor: '#F9BC23', background: 'linear-gradient(122deg, #FEFFF0 30%, rgba(255, 250, 100, 0.34) 60%)' }}><CiBadgeDollar size='1.8em' /> Transfer</Card.Header>
                <Card.Body>
                    <Form.Select style={{ borderColor: '#F9BC23' }} border="warning" size="md" onChange={onChangeCrossType}>
                        <option value="26">Polygon → uChain</option>
                        <option value="62">uChain → Polygon</option>
                    </Form.Select>
                    <Form.Select style={{ borderColor: '#F9BC23', marginBlock: '1em' }} size="md" onChange={onChangeCoin}>
                    <option value={TOKEN_USDT_ID}>USDT</option>
                    <option value={TOKEN_TBT_ID}>TBT</option>
                    </Form.Select>
                    <InputGroup className="mb-3" size="md">
                        <InputGroup.Text style={{ borderColor: '#F9BC23', background: 'linear-gradient(122deg, #FEFFC0 30%, rgba(255, 230, 98, 0.34) 60%)' }} id="basic-addon3">
                            Amount :
                        </InputGroup.Text>
                        <Form.Control style={{ borderColor: '#F9BC23' }} id="basic-url" aria-describedby="basic-addon3" placeholder="Please input amount" onChange={onChangeAmount} />
                    </InputGroup>
                    {notice !== '' ?
                        <Notice value={notice} setNotice={setNotice} />
                        : ''
                    }
                    {state.coin !== '' && state.srcChainId > 0 ?
                        <Button variant="warning" onClick={(e)=>{dispatch({type: 'status', data: {status: STATUS.start, element: e}})}} disabled={txBtnState.isLoading}>{txBtnState.text}</Button>
                        : ''
                    }
                </Card.Body>
            </Card>
        </>

    );
}

export default Transfer;
