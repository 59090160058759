import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import useWeb3 from "../contexts/Web3Context/useWeb3";

function Header() {

    const useweb3 = useWeb3();
    const {networkId, account} = useweb3.state;
    const conn = async (eventKey, e) => {
        useweb3.dispatch({data:{targetNetworkId: eventKey * 1}});
    }

    return (
        <Navbar style={{background: 'linear-gradient(122deg,#ffc107,#FFD44A 50%, #ffc107)'}} collapseOnSelect expand="md" onSelect={conn}>
            <Container>
                <Navbar.Brand href="#" style={{fontWeight: 'bolder'}}>
                    <img
                    alt=""
                    src="/logo-180x180.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    />{' '}
                    uBridge
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav>
                        <Nav.Item>
                        {networkId !== 792 || !account 
                        ? <Nav.Link eventKey={792} href="#"> Connect to uChain </Nav.Link> 
                        : <Nav.Link eventKey={137} href="#" > Connect to Polygon </Nav.Link>}
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;