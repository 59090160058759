const TOKEN_UCC_ID = 0;
const TOKEN_USDT_ID = 1;
const TOKEN_TBT_ID = 2;

const chainInfo = {
    "137": {
        "name": "Polygon",
        "crossTokenHub": "0x06ff389805E1Ef11e152fB5D8Bb331Af6a915506",
        "scanLink" : 'https://polygonscan.com/tx/',
        "tokens": {
            1: {
                "contractAddr": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
                "decimals": 6,
                "symbol": 'USDT',
                "logo": "https://polygonscan.com/token/images/tether_32.png",
            },
            2: {
                "contractAddr": "0x0013541eb181817cd81072f8f121a70ba0bfc2fd",
                "decimals": 18,
                "symbol": 'TBT',
                "logo": "https://s3.ap-southeast-1.amazonaws.com/utrading.io/logo180/TBT.png",
            }
        },
    },
    "792": {
        "name": "uChain",
        "crossTokenHub": "0x22C8C3d99937387a4938281C335B75e82f456AF4",
        "scanLink" : 'https://uchain.ai/tx/',
        "tokens": {
            1: {
                "contractAddr": "0x70C5A21b25ee21d84E537BD9981A97A42c7eB209",
                "decimals": 18,
                "symbol": 'USDT',
                "logo": "https://polygonscan.com/token/images/tether_32.png",
            },
            2: {
                "contractAddr": "0xbd644523afDb95B24Ef439d40a194b591CB7fdB2",
                "decimals": 18,
                "symbol": 'TBT',
                "logo": "https://s3.ap-southeast-1.amazonaws.com/utrading.io/logo180/TBT.png",
            }
        },
    }
};

const contractInfo = {
    '0x0013541eb181817cd81072f8f121a70ba0bfc2fd': {
        'symbol': 'TBT',
        decimals: 18,
    },    
    '0xbd644523afdb95b24ef439d40a194b591cb7fdb2': {
        'symbol': 'TBT',
        decimals: 18,
    },
    '0xc2132d05d31c914a87c6611c10748aeb04b58e8f': {
        'symbol': 'USDT',
        decimals: 6,
    },
    '0x70c5a21b25ee21d84e537bd9981a97a42c7eb209': {
        'symbol': 'USDT',
        decimals: 18,
    }
};

const chainConfig = {
    137: {
        chainId: "0x89",
        chainName: "Polygon Mainnet",
        rpcUrls: [
            'https://polygon-mainnet.infura.io/v3/3408b1b0191f4878ad4e780d156a1641'
        ],
        iconUrls: [
        ],
        blockExplorerUrls: [
            'https://crascan.com'
        ],
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18
        }
    },
    792: {
        chainId: '0x318',
        chainName: 'uChain',
        nativeCurrency: {
            name: 'UCC',
            symbol: 'UCC',
            decimals: 18,
        },
        rpcUrls: ['https://rpc.uchain.ai'],
        blockExplorerUrls: ['https://uchain.ai/'],
    },
};

const myChainId = 792
// const bridgeApi = 'http://127.0.0.1:8888/records/';
const bridgeApi = 'https://api.ubridge.ai';

export {
    chainInfo,
    contractInfo,
    chainConfig,
    bridgeApi,
    myChainId,
    TOKEN_UCC_ID,
    TOKEN_USDT_ID,
    TOKEN_TBT_ID
};
