const actions = {
  init: "INIT",
  notice: "NOTICE",
  error: "ERROR",
  account: "ACCOUNT",
  conn: "CONN",
};

const initialState = {
  web3: null,
  targetNetworkId: 0,
  account: '',
  networkId: 0,
  web3error: '',
};

const reducer = (state, action) => {
  //const { type , data } = action;
  return { ...state, ...action.data };
  // switch (type) {
  //   case actions.init:
  //     return { ...state, ...data };
  //   case actions.error:
  //     return { ...state, ...data };
  //   case actions.notice:
  //     return { ...state, ...data };
  //   case actions.account:
  //     return { ...state, ...data };
  //   case actions.conn:
  //     return { ...state, ...data };
  //   case actions.conn:
  //     return { ...state, ...data };
  //   default:
  //     throw new Error("Undefined reducer action type");
  // }
};

export {
  actions,
  initialState,
  reducer
};
