import Index from "./components";
import "./App.css";
import {Web3Provider, Notice} from "./contexts/Web3Context";
import Header from "./components/Header";

function App() {
    return (
        <Web3Provider>
            <Header />
            <div id="App" >
                <div className="container">
                    <Index />
                    <Notice />
                </div>
            </div>
        </Web3Provider>
    );
}

export default App;
