import Transfer from "./Transfer";
import Records from "./Records";
import Conn from "./Conn";
import Account from "./Account";

function Index() {
    return (
        <div className="contract-container">
            <Account />
            <Transfer />
            <Records />
            <Conn />
        </div>
    );
}

export default Index;